export interface Frame {
  id?: number;
  color: string;
}

export interface PricePerQuantity {
  min_quantity: number;
  max_quantity: number;
  price: number;
}

export interface OrderItemOption {
  id: number;
  label: string;
  value: string;
  price?: number;
  price_per_quantity?: PricePerQuantity[];
  number_of_photos?: number;
}

export class OrderItemsConstants {
  shirtConstants: {
    sizes: OrderItemOption[],
    sides: OrderItemOption[],
  };

  puzzleConstants: {
    dimensions: OrderItemOption[],
  };

  calendarConstants: {
    dimensions: OrderItemOption[],
    frames: Frame[],
  };

  canvasConstants: {
    dimensions: OrderItemOption[],
  };

  mugConstants: {
    type: OrderItemOption[],
  };

  bookConstants: {
    dimensions: OrderItemOption[],
  };

  printConstants: {
    dimensions: OrderItemOption[],
    paper: OrderItemOption[],
  };
}
