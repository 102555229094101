import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { OrderItem } from '../../models/order-item';

@Component({
  selector   : 'app-photo-album-card',
  templateUrl: './photo-album-card.component.html',
  styleUrls  : ['./photo-album-card.component.scss'],
})
export class PhotoAlbumCardComponent implements OnInit {
  @Input() album: OrderItem;
  @Output() addToCart: EventEmitter<OrderItem> = new EventEmitter<OrderItem>();
  public baseUrl = environment.storageUrl;

  constructor() {
  }

  ngOnInit() {
  }

  onAddToCart() {
    this.addToCart.emit(this.album);
  }

  public shouldRenderOldPrice() {
    return this.album.old_price !== this.album.price;
  }
}
