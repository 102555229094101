import { OrderImage } from './order-image';

export class OrderItem {
  id: number | string;
  property_id: number;
  type?: string;
  type_id?: number;
  photo_gift_id?: string | number;
  price_per_unit: number;
  price: number;
  old_price: number;
  quantity?: number;
  photos?: OrderImage[] = [];
  complete              = false;
  public label: string;
  thumbnail: string;
  comment?: string;
  description?: string;
  // puzzle
  puzzle_dimension?: string;

  // mug
  mug_type?: string;
  mug_text?: string;

  // shirts
  shirt_size?: string;
  shirt_side?: string;
  shirt_text?: string;

  // canvas
  canvas_dimension?: string;

  // photo_book
  photo_book_dimension?: string;

  // calendar
  calendar_dimension?: string;
  frame_id?: number;

  // gifts
  gift_type?: string;
  gift_per_type_option?: string;

  // photo printing
  photo_print_dimension?: string;
  photo_paper_type?: string;
}
