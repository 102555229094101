export const orderIdStorageKey = 'orderIdStorageKey';

export const storeCoordinates = {
  lat: 44.8100088564438,
  lng: 20.466469558279254,
};

export const postcodeRegex = `^\\d{5}$`;

export const upsellMaxTime = 15 * 60;
