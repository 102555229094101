import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector   : 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls  : ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit {
  @Input() formControl: string;
  @Input() placeholder: string;
  @Input() parentForm: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }

}
