import { Action, createReducer, on } from '@ngrx/store';
import * as OrderItemActions from './order-item.actions';
import { OrderItem } from '../../_shared/models/order-item';

export const orderItemFeatureKey = 'orderItem';

export interface State {
  orderItem: OrderItem;
  isImageUploading: boolean;
  photoAlbums: OrderItem[];
  isPhotoAlbumsLoading: boolean;
}

export const initialState: State = {
  orderItem           : new OrderItem(),
  isImageUploading    : false,
  photoAlbums         : [],
  isPhotoAlbumsLoading: false
};


export const reducer = createReducer(
  initialState,

  on(OrderItemActions.createOrderItems, (state = initialState, action) => {
    return {
      ...state,
      orderItem: action.data
    };
  }),

  on(OrderItemActions.updateOrderItems, (state = initialState, action) => {
    return {
      ...state,
      orderItem: {...state.orderItem, ...action.data}
    };
  }),

  on(OrderItemActions.getPhotoAlbums, (state = initialState) => {
    return {
      ...state,
      isPhotoAlbumsLoading: true
    };
  }),
  on(OrderItemActions.getPhotoAlbumsSuccess, (state = initialState, action) => {
    return {
      ...state,
      isPhotoAlbumsLoading: false,
      photoAlbums         : action.data
    };
  }),
  on(OrderItemActions.getPhotoAlbumsFailure, (state = initialState) => {
    return {
      ...state,
      isPhotoAlbumsLoading: false
    };
  }),

  on(OrderItemActions.uploadOrderItemImage, (state = initialState, action) => {
    return {
      ...state,
      isImageUploading: true
    };
  }),
  on(OrderItemActions.uploadOrderItemImageSuccess, (state = initialState, action) => {
    const updatedOrderItem = {...state.orderItem};
    updatedOrderItem.photos.unshift(action.data);
    return {
      ...state,
      isImageUploading: false,
      orderItem       : updatedOrderItem
    };
  }),
  on(OrderItemActions.uploadOrderItemImageFailure, (state = initialState, action) => {
    return {
      ...state,
      isImageUploading: false
    };
  })
);

