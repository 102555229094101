import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PhotoGift } from '../../models/photo-gift';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-special-offer-add-ons',
  templateUrl: './special-offer-add-ons.component.html',
  styleUrls: ['./special-offer-add-ons.component.scss'],
})
export class SpecialOfferAddOnsComponent implements OnInit {
  @Input() extra: PhotoGift;
  @Output() quantityChange: EventEmitter<{ item: PhotoGift; quantity: number }> =
              new EventEmitter<{ item: PhotoGift ; quantity: number }>();
  @Output() checkedChange: EventEmitter<{ item: PhotoGift; checked: boolean }> =
              new EventEmitter<{ item: PhotoGift; checked: boolean }>();

  public baseUrl              = environment.storageUrl;
  public quantity = 0;
  constructor() { }

  ngOnInit() {}

  onQuantityChange($event: number) {
    this.quantity = $event;
    this.quantityChange.emit({item: this.extra, quantity: this.quantity});
  }

  onCheckBoxClick() {
    if (this.quantity === 0 ) {
      this.quantity = 1;
    } else {
      this.quantity = 0;
    }
    this.checkedChange.emit({item: this.extra, checked: !!this.quantity});
  }
}
