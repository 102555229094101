import { BaseApiService } from '../base-api.service';
import { Injectable } from '@angular/core';
import { Order } from '../../../_shared/models/order';
import { Observable } from 'rxjs';
import { OrderItem } from '../../../_shared/models/order-item';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseApiService {
  updateOrder(order: Order): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/order`, order);
  }

  getOrder(orderId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/order/${orderId}`);
  }

  getOrderItemsConstants(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/properties`);
  }

  createOrder(): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/order`, {});
  }

  getDeliveryCost(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/delivery`);
  }

  getOrderBumpOffer(): Observable<{ data: OrderItem[] }> {
    return this.http.get<{ data: OrderItem[] }>(`${this.baseUrl}/order-bump-offers`);
  }

  getOrderUpsellOffer(): Observable<{ data: OrderItem[] }> {
    return this.http.get<{ data: OrderItem[] }>(`${this.baseUrl}/product-upsells`);
  }
}
