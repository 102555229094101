import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './store';
import { OrderEffects } from './store/order/order.effects';
import { OrderItemEffects } from './store/order-item/order-item.effects';
import { SharedModule } from './_shared/shared.module';
import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations   : [AppComponent],
  entryComponents: [],
  imports        : [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApi
    }),
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([OrderEffects, OrderItemEffects])
  ],
  providers      : [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, ImagePicker],
  bootstrap      : [AppComponent],
})
export class AppModule {
}
