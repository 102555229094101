import { BaseApiService } from '../base-api.service';
import { Injectable } from '@angular/core';
import { Order } from '../../../_shared/models/order';
import { Observable, of } from 'rxjs';
import { LaravelResponse } from '../../../_shared/models/laravel-response';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseApiService {
  updateOrder(order: Order): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/order`, order);
  }

  getOrder(orderId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/order/${orderId}`);
  }

  getOrderItemsConstants(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/properties`);
  }

  createOrder(): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/order`, {});
  }

  getDeliveryCost(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/delivery`);
  }
}
