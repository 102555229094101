import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector   : 'app-frame-picker-modal',
  templateUrl: './frame-picker-modal.component.html',
  styleUrls  : ['./frame-picker-modal.component.scss'],
})
export class FramePickerModalComponent implements OnInit {
  @Input() frames;

  constructor(
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
  }

  chooseFrame(frameId = -1) {
    this.modalController.dismiss(frameId);
  }
}
