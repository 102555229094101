import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector   : 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls  : ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  public photoBookUrl = environment.photoBookUrl;

  constructor(
    private menu: MenuController
  ) {
  }

  ngOnInit() {
  }

  closeMenu() {
    this.menu.close();
  }
}
