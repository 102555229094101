import { BaseApiService } from '../base-api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PhotoGift } from '../../../_shared/models/photo-gift';
import { SpecialOffer } from '../../../_shared/models/special-offer';

@Injectable({
  providedIn: 'root'
})
export class OrderItemService extends BaseApiService{
  uploadImage(image): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/order/photo`, image);
  }

  getPhotoAlbums(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/photo-albums`);
  }

  getPhotoGifts(): Observable<PhotoGift[]> {
    return this.http.get<PhotoGift[]>(`${this.baseUrl}/photo-gifts`);
  }

  getSpecialOffers(): Observable<SpecialOffer[]> {
    return this.http.get<SpecialOffer[]>(`${this.baseUrl}/special-offers`);
  }
}
