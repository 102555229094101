import { createAction, props } from '@ngrx/store';
import { Order } from '../../_shared/models/order';
import { OrderItemsConstants } from '../../_shared/constants/order-items-constants';

export const loadOrder = createAction(
  '[Order] Load Order'
);

export const loadOrderSuccess = createAction(
  '[Order] Load Order Success',
  props<{ data: any }>()
);

export const loadOrderFailure = createAction(
  '[Order] Load Order Failure',
  props<{ error: any }>()
);

export const updateOrderItems = createAction(
  '[Order] Update Order Items',
  props<{ data: Order, orderItemCompleted: boolean, isPhotoAlbum?: boolean }>()
);
export const updateOrderItemsSuccess = createAction(
  '[Order] Update Order Items Success',
  props<{ data: Order }>()
);
export const updateOrderItemsFailure = createAction(
  '[Order] Update Order Items Failure',
  props<{ error: any }>()
);

export const getOrderItemsConstants = createAction(
  '[Order] Get Order Items Constants',
);
export const getOrderItemsConstantsSuccess = createAction(
  '[Order] Get Order Items Constants Success',
  props<{ data: OrderItemsConstants }>()
);
export const getOrderItemsConstantsFailure = createAction(
  '[Order] Get Order Items Constants Failure',
  props<{ error: any }>()
);
