export const MUG_TYPE = {
  label: 'Foto Šolja',
  name: 'photo_mug',
  id  : 1
};
export const BOOK_TYPE = {
  label: 'Foto Knjiga',
  name: 'photo_book',
  id  : 2
};
export const SHIRT_TYPE = {
  label: 'Foto Majica',
  name: 'photo_shirt',
  id  : 3
};
export const CALENDAR_TYPE = {
  label: 'Foto Kalendar',
  name: 'photo_calendar',
  id  : 4
};
export const CANVAS_TYPE = {
  label: 'Foto Canvas',
  name: 'photo_canvas',
  id  : 5
};
export const PUZZLE_TYPE = {
  label: 'Foto Puzle',
  name: 'photo_puzzle',
  id  : 6
};
export const PHOTO_TYPE = {
  label: 'Izrada Fotografija',
  name: 'photo_printing',
  id  : 7
};
export const ALBUM_TYPE = {
  label: 'Foto Album',
  name: 'photo_album',
  id  : 13
};
export const DISCOUNT_TYPE = {
  label: 'Akcija kalendar, 2 + 1 gratis',
  name: 'popustu',
  id  : 14
};

export const PHOTO_PRINTING_EXTRAS = {
  label: 'Dodaci',
  name: 'photo_printing_extra',
  id  : 16
};

export const ORDER_BUMP_ITEM = {
  label: 'Speccijalna ponuda',
  name: 'order_bump',
  id  : 17
};
