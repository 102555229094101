import { createAction, props } from '@ngrx/store';
import { OrderItem } from '../../_shared/models/order-item';
import { OrderImage } from '../../_shared/models/order-image';

export const createOrderItems = createAction(
  '[OrderItem] Create OrderItems',
  props<{ data: OrderItem }>()
);

export const updateOrderItems = createAction(
  '[OrderItem] Update OrderItems',
  props<{ data: OrderItem }>()
);

export const uploadOrderItemImage = createAction(
  '[OrderItem] Upload OrderItem Image',
  props<{ data: any }>()
);
export const uploadOrderItemImageSuccess = createAction(
  '[OrderItem] Upload OrderItem Image Success',
  props<{ data: OrderImage }>()
);
export const uploadOrderItemImageFailure = createAction(
  '[OrderItem] Upload OrderItem Image Failure',
  props<{ error: any }>()
);

export const getPhotoAlbums = createAction(
  '[OrderItem] Get Photo Albums'
);
export const getPhotoAlbumsSuccess = createAction(
  '[OrderItem] Get Photo Albums Success',
  props<{ data: OrderItem[] }>()
);
export const getPhotoAlbumsFailure = createAction(
  '[OrderItem] Get Photo Albums Failure',
  props<{ error: any }>()
);

