import { State as OrderState, reducer as orderReducer } from './order/order.reducer';
import { State as OrderItemState, reducer as orderItemReducer } from './order-item/order-item.reducer';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

export interface State {
  order: OrderState;
  orderItem: OrderItemState;
}

export const reducers: ActionReducerMap<State> = {
  order    : orderReducer,
  orderItem: orderItemReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
