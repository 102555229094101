import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector   : 'app-animated-button',
  templateUrl: './animated-button.component.html',
  styleUrls  : ['./animated-button.component.scss'],
})
export class AnimatedButtonComponent implements OnInit {
  @Input() label: string;
  @Input() color: string;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();


  constructor() {
  }

  ngOnInit() {
  }

  onClick() {
    this.clickEvent.emit();
  }
}
