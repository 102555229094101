import { LoadingController } from '@ionic/angular';

export async function presentLoader(loaderController: LoadingController, duration = 2000) {
  const loading = await loaderController.create({
    duration,
    message: 'Molimo sačekajte...',
    translucent: false,
    cssClass: 'custom-class custom-loading',
    backdropDismiss: false
  });
  await loading.present();
}
