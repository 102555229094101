import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimatedButtonComponent } from './components/animated-button/animated-button.component';
import { FooterComponent } from './layout/footer/footer.component';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './layout/header/header.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { CouponInputComponent } from './components/cupon-input/coupon-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { QuantityInputComponent } from './components/quantity-input/quantity-input.component';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { InfoWithLabelComponent } from './components/info-with-label/info-with-label.component';
import { TotalPriceComponent } from './components/total-price/total-price.component';
import { ProductPreviewCardComponent } from './components/product-preview-card/product-preview-card.component';
import { RouterModule } from '@angular/router';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import { HttpClientModule } from '@angular/common/http';
import { FormInputComponent } from './components/form-input/form-input.component';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { FramePickerModalComponent } from './components/frame-picker-modal/frame-picker-modal.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { PhotoAlbumCardComponent } from './components/photo-album-card/photo-album-card.component';
import { CommentModalComponent } from './components/comment-modal/comment-modal.component';
import { SpecialOfferModalComponent } from './components/special-offer-modal/special-offer-modal.component';
import { SpecialOfferAddOnsComponent } from './components/special-offer-add-ons/special-offer-add-ons.component';



@NgModule({
  declarations: [
    AnimatedButtonComponent,
    FooterComponent,
    HeaderComponent,
    StepperComponent,
    CouponInputComponent,
    QuantityInputComponent,
    FormInputComponent,
    SelectInputComponent,
    InfoWithLabelComponent,
    TotalPriceComponent,
    ImagePickerComponent,
    ProductPreviewCardComponent,
    SideMenuComponent,
    FramePickerModalComponent,
    LoadingButtonComponent,
    PhotoAlbumCardComponent,
    CommentModalComponent,
    SpecialOfferModalComponent,
    SpecialOfferAddOnsComponent
  ],
  exports: [
    AnimatedButtonComponent,
    FooterComponent,
    HeaderComponent,
    StepperComponent,
    CouponInputComponent,
    FormInputComponent,
    QuantityInputComponent,
    SelectInputComponent,
    InfoWithLabelComponent,
    TotalPriceComponent,
    ImagePickerComponent,
    ProductPreviewCardComponent,
    HttpClientModule,
    SideMenuComponent,
    FramePickerModalComponent,
    LoadingButtonComponent,
    PhotoAlbumCardComponent,
    CommentModalComponent,
    SpecialOfferModalComponent,
    SpecialOfferAddOnsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
  ]
})
export class SharedModule { }
