import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as OrderActions from './order.actions';
import { OrderService } from '../../services/api/order/order.service';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { orderIdStorageKey } from '../../_shared/constants/constants';
import { presentLoader } from '../../_shared/heplers/global-loader';
import { OrderItemsConstants } from '../../_shared/constants/order-items-constants';
import {
  BOOK_TYPE, CALENDAR_TYPE, CANVAS_TYPE,
  MUG_TYPE, PHOTO_TYPE, PUZZLE_TYPE, SHIRT_TYPE
} from '../../_shared/constants/product-types';


@Injectable()
export class OrderEffects {

  private allProductTypes = [
    {...MUG_TYPE, constantKey: 'mugConstants'},
    {...BOOK_TYPE, constantKey: 'bookConstants'},
    {...SHIRT_TYPE, constantKey: 'shirtConstants'},
    {...CALENDAR_TYPE, constantKey: 'calendarConstants'},
    {...CANVAS_TYPE, constantKey: 'canvasConstants'},
    {...PUZZLE_TYPE, constantKey: 'puzzleConstants'},
    {...PHOTO_TYPE, constantKey: 'printConstants'},
  ];


  getOrderItemsConstants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.getOrderItemsConstants),
      concatMap(() => {
        return this.orderService.getOrderItemsConstants()
          .pipe(
            map(response => {
              const parsedConstants: OrderItemsConstants = new OrderItemsConstants();

              response.map(property => {
                const itemType = this.allProductTypes.find(type => type.id === property.type_id);

                parsedConstants[itemType.constantKey] = {
                  ...parsedConstants[itemType.constantKey],
                  [property.app_name]: property.property_items
                };
              });

              return OrderActions.getOrderItemsConstantsSuccess({data: parsedConstants});
            }),
            catchError(error => of(OrderActions.getOrderItemsConstantsFailure({error})))
          );
      }));
  });

  loadOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadOrder),
      mergeMap(() => {
          void presentLoader(this.loadingController);
          const orderId = localStorage.getItem(orderIdStorageKey);
          let serviceObservable: Observable<any>;
          // if we have order id, we fetch for it, otherwise we create new one
          if (orderId && orderId !== 'undefined') {
            serviceObservable = this.orderService.getOrder(orderId);
          } else {
            serviceObservable = this.orderService.createOrder();
          }
          return serviceObservable.pipe(
            switchMap(response => {
              void this.loadingController.dismiss();
              localStorage.setItem(orderIdStorageKey, response?.uuid);
              if (response.complete) {
                void this.navController.navigateRoot('purchase-finished');
              }
              return [OrderActions.getOrderItemsConstants(), OrderActions.loadOrderSuccess({data: response})];
            }),
            catchError(error => of(OrderActions.loadOrderFailure({error}))));
        }
      )
    );
  });

  updateOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.updateOrderItems),
      concatMap((action) => {
          presentLoader(this.loadingController);
          return this.orderService.updateOrder(action.data).pipe(
            map(response => {
              this.loadingController.dismiss();
              if (action.orderItemCompleted) {
                this.toastController.create({
                  message : 'Proizvod uspešno dodat u korpu.',
                  duration: 15000,
                  buttons: [
                    {
                      text: 'Idi u korpu',
                      role: 'info',
                      handler: () => {
                        void this.navController.navigateRoot('cart');
                      },
                    }
                  ],
                  color   : 'success',
                })
                  .then(toast => {
                    toast.present();
                    if (!action.isPhotoAlbum) {
                      this.navController.navigateRoot('products').then();
                    }
                  });
              }
              if (action.data.complete) {
                this.toastController.create({
                  message : 'Vaša narudžbina je uspešno poslata.',
                  duration: 2000,
                  color   : 'success',
                }).then(toast => {
                  toast.present();
                });
                void this.navController.navigateRoot('purchase-finished');
                // localStorage.setItem(orderIdStorageKey, '');
                return OrderActions.loadOrder();
              }
              return OrderActions.updateOrderItemsSuccess({data: response});
            }),
            catchError(error => of(OrderActions.updateOrderItemsFailure({error}))));
        }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private orderService: OrderService,
    private navController: NavController,
    public toastController: ToastController,
    public loadingController: LoadingController
  ) {
  }

}
