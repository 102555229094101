import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector   : 'app-product-preview-card',
  templateUrl: './product-preview-card.component.html',
  styleUrls  : ['./product-preview-card.component.scss'],
})
export class ProductPreviewCardComponent implements OnInit {
  @Input() productName: string;
  @Input() productPhoto: string;

  constructor() {
  }

  ngOnInit() {
  }

}
