import { Component, Input, OnInit } from '@angular/core';
import { OrderItem } from '../../models/order-item';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss'],
})
export class CommentModalComponent implements OnInit {
  @Input() album: OrderItem;
  public baseUrl = environment.storageUrl;

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  public close() {
    void this.modalController.dismiss();
  }

  public submit(commentValue: string) {
    void this.modalController.dismiss({
      comment: commentValue
    });
  }
}
