import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input() duration = 60; // Countdown duration in seconds
  @Output() countdownFinished = new EventEmitter<void>(); // Event to emit when countdown finishes

  currentTime: number = this.duration;
  private countdownSubscription: Subscription | null = null;

  ngOnInit(): void {
    this.startCountdown();
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  private startCountdown(): void {
    this.currentTime = this.duration;

    // Start an interval that emits every second and takes only the number of emissions equal to `duration`
    this.countdownSubscription = interval(1000)
      .pipe(take(this.duration))
      .subscribe({
        next: () => this.currentTime--,
        complete: () => this.onCountdownComplete()
      });
  }

  get formattedTime(): string {
    const minutes = Math.floor(this.currentTime / 60);
    const seconds = this.currentTime % 60;
    return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  private onCountdownComplete(): void {
    this.countdownFinished.emit(); // Emit the event when countdown completes
  }
}
