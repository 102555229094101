import { createReducer, on } from '@ngrx/store';
import * as OrderActions from './order.actions';
import { Order } from '../../_shared/models/order';
import { OrderItemsConstants } from '../../_shared/constants/order-items-constants';

export const orderFeatureKey = 'order';

export interface State {
  order: Order;
  loadingOrder: boolean;
  updatingOrder: boolean;
  orderItemsConstants: OrderItemsConstants;
}

export const initialState: State = {
  order: {
    phone: null,
    address: '',
    order_id: null,
    items: [],
    city: null,
    complete: null,
    coupon: null,
    delivery_address: null,
    delivery_city: null,
    email: null,
    name: null,
    pickup: null,
    uuid: null,
  },
  loadingOrder: false,
  updatingOrder: false,
  orderItemsConstants: new OrderItemsConstants()
};


export const reducer = createReducer(
  initialState,

  on(OrderActions.loadOrder, state => initialState),
  on(OrderActions.loadOrderSuccess, (state, action) => {
    return {
      ...state,
      order: action.data,
    };
  }),
  on(OrderActions.loadOrderFailure, (state, action) => state),

  on(OrderActions.updateOrderItems, (state, action) => {
    return {
      ...state,
      updatingOrder: true
    };
  }),
  on(OrderActions.updateOrderItemsSuccess, (state, action) => {
    return {
      ...state,
      order: action.data,
      updatingOrder: false
    };
  }),
  on(OrderActions.updateOrderItemsFailure, (state, action) => {
    return {
      ...state,
      updatingOrder: false
    };
  }),
  on(OrderActions.getOrderItemsConstants, (state, action) => {
    return {
      ...state
    };
  }),
  on(OrderActions.getOrderItemsConstantsSuccess, (state, action) => {
    return {
      ...state,
      orderItemsConstants: action.data,
    };
  }),
  on(OrderActions.getOrderItemsConstantsFailure, (state, action) => {
    return {
      ...state
    };
  }),
);

