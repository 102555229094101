import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as OrderItemActions from './order-item.actions';
import { of } from 'rxjs';
import { OrderItemService } from '../../services/api/order-item/order-item.service';
import { presentLoader } from '../../_shared/heplers/global-loader';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class OrderItemEffects {
  getPhotoAlbums$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderItemActions.getPhotoAlbums),
      concatMap((action) => {
          presentLoader(this.loadingController, 1000);
          return this.orderItemService.getPhotoAlbums().pipe(
            map((response) => {
              return OrderItemActions.getPhotoAlbumsSuccess(
                {data: response[0].property_items.map(album => ({...album, type_id: response[0].type_id}))});
            }),
            catchError(error => of(OrderItemActions.getPhotoAlbumsFailure({error}))));
        }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private orderItemService: OrderItemService,
    public loadingController: LoadingController
  ) {
  }

}
