import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-price',
  templateUrl: './total-price.component.html',
  styleUrls: ['./total-price.component.scss'],
})
export class TotalPriceComponent implements OnInit {
  @Input() price: number | string;

  constructor() { }

  ngOnInit() {}

}
