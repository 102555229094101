import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector   : 'app-coupon-input',
  templateUrl: './coupon-input.component.html',
  styleUrls  : ['./coupon-input.component.scss'],
})
export class CouponInputComponent implements OnInit {
  @Output() applyCoupon: EventEmitter<string> = new EventEmitter<string>();
  public couponForm: FormGroup;
  public isChecking                           = false;
  public couponValid                          = false;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.initForm();
  }

  /**
   * Initialize form
   */
  initForm() {
    this.couponForm = this.fb.group({
      coupon: ''
    });
  }

  /**
   * Emits coupon value to get handled in parent
   */
  public onSubmit() {
    this.isChecking = true;
    this.applyCoupon.emit(this.couponForm.value.coupon);
    setTimeout(() => {
      this.isChecking = false;
      this.couponValid = true;
    }, 400);
  }
}
