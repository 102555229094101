import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from './store';
import { loadOrder } from './store/order/order.actions';
import { Platform } from '@ionic/angular';

@Component({
  selector   : 'app-root',
  templateUrl: 'app.component.html',
  styleUrls  : ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private store: Store<State>,
    public platform: Platform
  ) {
    this.store.dispatch(loadOrder());
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes('wv') && platform.is('android')) {
      window.location.href = 'intent://app.fotoblicurosevic.rs#Intent;scheme=https;action=android.intent.action.VIEW;end';
    }

    // TO-DO for mobile device only
    //
    /* initialize() is important for iOS,
      Android doesn't need any initialization.
    */
    // CapacitorGoogleMaps.initialize({
    //   key: 'YOUR_IOS_API_KEY'
    // });
  }
}
