import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { State } from '../../../store';
import { getNumberOfProductsInCart } from '../../../store/order/order.selectors';
import { environment } from '../../../../environments/environment';

@Component({
  selector   : 'app-header',
  templateUrl: './header.component.html',
  styleUrls  : ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() hideNavigation: boolean;
  public finishedOrderItemsNumber = 0;
  public photoBookUrl = environment.photoBookUrl;

  constructor(
    private menu: MenuController,
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    this.getFinishedOrderItems();
  }

  getFinishedOrderItems() {
    this.store.pipe(select(getNumberOfProductsInCart))
      .pipe(filter(res => !!res))
      .subscribe(res => {
        this.finishedOrderItemsNumber = res;
      });
  }

  toggleMenu() {
    this.menu.toggle();
  }
}
