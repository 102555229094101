import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOrder from './order.reducer';
import { DISCOUNT_TYPE } from '../../_shared/constants/product-types';

export const selectOrderState = createFeatureSelector<fromOrder.State>(
  fromOrder.orderFeatureKey
);

export const orderItemConstants = createSelector(
  selectOrderState,
  state => state.orderItemsConstants
);

export const isFetchingOrder = createSelector(
  selectOrderState,
  state => state.loadingOrder
);

export const getFinishedOrderItems = createSelector(
  selectOrderState,
  state => state.order.items.filter(order => order.complete && order.quantity > 0)
);

export const getUnfinishedOrderItems = createSelector(
  selectOrderState,
  state => state.order.items.filter(order => !order.complete)
);

export const getNumberOfProductsInCart = createSelector(
  getFinishedOrderItems,
  orderItems => orderItems.filter(order => order.type_id !== DISCOUNT_TYPE.id).length
);
export const getUnfinishedOrderItemByType = (type_id) => createSelector(
  selectOrderState,
  state => state.order.items.find(order => order.type_id === type_id && !order.complete)
);

export const getOrder = createSelector(
  selectOrderState,
  state => state.order
);

