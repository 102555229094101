import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector   : 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls  : ['./quantity-input.component.scss'],
})
export class QuantityInputComponent implements OnInit {
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() quantity;
  @Input() shouldBeSmall: boolean;
  @Input() disabled: boolean;
  @Input() light: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  increase() {
    this.quantity = this.quantity + 1 % 100;
    this.valueChange.emit(this.quantity);
  }

  decrease() {
    this.quantity = this.quantity - 1 || 1;
    this.valueChange.emit(this.quantity);
  }

}
