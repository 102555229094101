import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SpecialOffer } from '../../models/special-offer';

@Component({
  selector: 'app-special-offer-modal',
  templateUrl: './special-offer-modal.component.html',
  styleUrls: ['./special-offer-modal.component.scss'],
})
export class SpecialOfferModalComponent implements OnInit {
  @Input() offer: SpecialOffer;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  makePriceTwoDigit(price: number) {
    return price.toFixed(2);
  }

  onClose() {
    this.modalController.dismiss();
  }
}
